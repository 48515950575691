import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import gsm from "../../Assets/Projects/gsm.png";
import placement from "../../Assets/Projects/placement.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
         <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gsm}
              isBlog={false}
              title="Guard Management System"
              description="Guard Management System - Assign duty and track all performance of guard build with react.js, Material-UI,Express js and Nodejs. Have features which allows guard to realtime messaging, check his overall performance and can submit daily reports and emergency alert."
              ghLink="https://github.com/bishwjeet7"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={placement}
              isBlog={false}
              title="Placement Portal"
              description="Created for university and college have two dashboard students and teacher where teachers can upload and check all details of students. Created using HTML,css and javascript."
              ghLink="https://github.com/bishwjeet7/placement_portal.git"
              // demoLink="https://github.com/bishwjeet7/placement_portal.git"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="NewsMonkey"
              description="NewsMonkey, your go-to destination for staying informed and engaged! Our user-friendly website, crafted with the power of React and seamless API integration, offers you a comprehensive news-reading experience. Explore the latest headlines, and take control of your newsfeed by sorting articles based on your personal preferences and favorite categories. Stay updated and entertained with NewsMonkey's intuitive design and diverse content selection."
              ghLink="https://github.com/bishwjeet7/newsMonkey.git"
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          {/* <Col md={4} className="project-card">
             <ProjectCard
               imgPath={leaf}
               isBlog={false}
               title="Plant AI"
               description="Used the plant disease dataset from Kaggle and trained a image classifer model using 'PyTorch' framework using CNN and Transfer Learning with 38 classes of various plant leaves. The model was successfully able to detect diseased and healthy leaves of 14 unique plants. I was able to achieve an accuracy of 98% by using Resnet34 pretrained model."
               ghLink="https://github.com/soumyajit4419/Plant_AI"
               demoLink="https://plant49-ai.herokuapp.com/"
             />
           </Col>

           <Col md={4} className="project-card">
             <ProjectCard
               imgPath={suicide}
               isBlog={false}
               title="Ai For Social Good"
               description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
               ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
               // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
             />
           </Col>

           <Col md={4} className="project-card">
             <ProjectCard
            imgPath={emotion}
               isBlog={false}
               title="Face Recognition and Emotion Detection"
               description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
               ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
               // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
             />
           </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
